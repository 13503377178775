import 'react-app-polyfill/ie11';
import 'core-js/stable';
import axios from 'axios';
import qs from 'qs';



export const orderDocumentDownload = async (contentUrl) => {
  try {
      let res;
      if (window.config.businessUnit === 'PMT-HPS') {
        const documentDownloadAPI = window.config.documentDownloadAPI;
        res = await axios.post(window.config.documentDownloadAPIUrl, JSON.parse(contentUrl), { responseType: 'arraybuffer', headers: { 'x-functions-key': documentDownloadAPI.split("&=")[1], 'Content-Type': 'application/json', Accept: 'application/pdf', } });
      }
      else {
        res = await axios.post(window.config.documentDownloadAPIUrl, JSON.parse(contentUrl), { responseType: 'arraybuffer', headers: { 'x-functions-key': window.config.documentDownloadAPIkey, 'Content-Type': 'application/json', Accept: 'application/pdf', } });
      }
      return res;
  }
  catch (err) {
    //Code errors during failover scenario.
    return { "error": 'fetch failed.' }
  }

}
export default orderDocumentDownload;
